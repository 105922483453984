import React from "react";
import ErrorBoundry from "./component/ErrorBoundry";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./page/Home";
import CustomSnack from "./component/ui/CustomSnack";
// import FeedDetail from "./page/FeedDetail";
// import Login from "./page/Login";
import Signup from "./page/Signup";
import { Profile } from "./page/Profile";
// import { UserProfile } from "./page/UserProfile";
// import EditUserProfile from "./page/EditUserProfile";
import ChangePassword from "./page/ChangePassword";
// import EditUserProfile from "./page/EditUserProfile";
// import ChangePassword from "./page/ChangePassword";
import NotFound from "./page/NotFound";
import ForgotPassword from "./page/ForgotPassword";
import ResetPassword from "./page/ResetPassword";
import ResetEmail from "./page/ResetEmail";
import NewFeedDetails from "./page/NewFeedDetails";
import OtpVarify from "./page/OtpVarify";
import SignIn from "./page/SignIn";
import EmailVarification from "./page/EmailVarification";
// import { useRedirect } from "./hooks/store/useRedirect";
import { CreateCampaign } from "./page/CreateCampaign";
import Donation from "./page/Donate";
import DonationSuccess from "./page/DonateSuccess";
import DonationFailure from "./page/DonateFailure";
import BlockList from "./page/BlockList";
import WithdrawalHistory from "./page/withdrawal/WithdrawalHistory";
import TermAndCondition from "./page/TermAndCondition";
import SavedFeed from "./page/SavedFeed";
import OtpVerifyForm from "./component/editProfile/OtpVerifyForm";
import EditProfileForm from "./component/editProfile/EditProfileForm";
// import { Kyc } from "./page/Kyc";
import UpdateMobile from "./page/UpdateMobile";
import PrivacyPolicy from "./page/PrivacyPolicy";
import EditRaiseDashboard from "./page/EditRaiseDashboard";
import RearrangeMedia from "./page/RearrageMedia";
import UpdateRaise from "./page/UpdateRaise";
import SearchResult from "./page/SearchResult";
import VerifyIndentity from "./page/VerifyIndentity";
import AddAch from "./page/AddAch";
import ROUTE_LIST from "./Routes/list";
import FeedWithdrawal from "./page/FeedWithdrawal";
import WithdrawalConfirm from "./page/withdrawal/WithdrawalConfirm";
import CreateFeed from "./page/CreateFeed";
import checkForCreateFeedForm from "./Routes/middleware/checkForCreateFeedForm";
import CreateDraft from "./page/CreateDraft";
import Comments from "./page/Comments";
import DonarHistory from "./page/DonarHistory";
import Settings from "./page/Settings";
import ComingSoon from "./page/ComingSoon";
import DonateAndTipping from "./page/DonateAndTipping";
import Notification from "./page/Notification";
// import LeaderBoard from "./page/LeaderBoard";

const routes = createBrowserRouter([
  { path: "/", Component: Home },
  { path: "/raise", Component: Home },
  { path: "/story", Component: Home },
  { path: ROUTE_LIST.FEED_DETAIL + ":id", Component: NewFeedDetails },
  // { path: "/feedd/:id", Component: NewFeedDetails },
  { path: "/signup", Component: Signup },
  { path: "/varify-otp", Component: OtpVarify },
  { path: "/signin", Component: SignIn },
  // { path: "/donate", Component: Donation },
  { path: "/donate", Component: DonateAndTipping },
  { path: "/donation-success", Component: DonationSuccess },
  { path: "/donation-failure", Component: DonationFailure },
  // { path: "/login", Component: Login },
  { path: "/profile", Component: Profile },
  { path: "/profile/:userId", Component: Profile },
  { path: ROUTE_LIST.FEED_CREATE.BASE, Component: CreateFeed },
  {
    path: ROUTE_LIST.FEED_CREATE.WILD_ROUTE,
    loader: checkForCreateFeedForm,
    Component: CreateCampaign,
  },
  // { path: ROUTE_LIST.WITHDRAWAL_HISTORY, Component: WithdrawalHistory },
  // { path: ROUTE_LIST.FEED_WITHDRAWAL.route, Component: FeedWithdrawal },
  // { path: ROUTE_LIST.WITHDRAWAL_CONFIRM, Component: WithdrawalConfirm },
  { path: "/block-list", Component: BlockList },
  // { path: "/edit-profile", Component: EditUserProfile },
  // { path: "/edit-profile", Component: EditUserProfile },
  { path: "/edit-profile", Component: EditProfileForm },
  // { path: "/change-password", Component: ChangePassword },
  { path: "/forgot-password", Component: ForgotPassword },
  { path: "/reset-password/:resetToken", Component: ResetPassword },
  { path: "/change-password", Component: ChangePassword },
  { path: "/reset-email", Component: ResetEmail },
  { path: "/change-number", Component: UpdateMobile },
  { path: "/saved-feed", Component: SavedFeed },
  { path: "/edit-profile", Component: OtpVerifyForm },
  { path: "/email-verification", Component: EmailVarification },
  { path: "/terms-and-condition", Component: TermAndCondition },
  { path: "/privacy-policy", Component: PrivacyPolicy },
  { path: "/identity-verification", Component: VerifyIndentity },
  { path: ROUTE_LIST.RAISE_DASHBOARD.route, Component: EditRaiseDashboard },
  { path: "/re-arrange/:id", Component: RearrangeMedia },
  { path: "/edit-raise/:id", Component: UpdateRaise },
  { path: "/search", Component: SearchResult },
  // { path: ROUTE_LIST.ADD_ACH, Component: AddAch },
  { path: ROUTE_LIST.DRAFT.CREATE, Component: CreateDraft },
  { path: ROUTE_LIST.COMMENT, Component: Comments },
  { path: ROUTE_LIST.DONATION_HISTORY, Component: DonarHistory },
  { path: ROUTE_LIST.SETTINGS, Component: Settings },
  { path: ROUTE_LIST.NOTIFICATION, Component: Notification },
  { path: "/404", Component: NotFound },
  { path: "/coming-soon", Component: ComingSoon },
  { path: "/*", Component: NotFound },
  // { path: "/leader-board", Component: LeaderBoard },
]);
export default function Routes() {
  // const { redirectUrl } = useRedirect();
  // if (true) {
  //   return redirect("/feed");
  // }
  return (
    <ErrorBoundry>
      <RouterProvider router={routes} fallbackElement="loading" />
      <CustomSnack />
    </ErrorBoundry>
  );
}
