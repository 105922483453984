import { useTheme, alpha } from "@mui/material";
import React, { useEffect, useState } from "react";
import ROUTE_LIST from "../../Routes/list";
import { useUserVerification } from "../../hooks/store/useUserVerification";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import { useAuth } from "../../hooks/store/useAuth";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import DialogBox from "../Dialogbox/DialogBox";
import EndRaiseDialog from "./EndRaiseDialog";
import { useSnack } from "../../hooks/store/useSnack";

export default function WithdrawalButton({
  feedId,
  endRaise = false,
  children,
  disabled = false,
  checkKyc = false,
}) {
  const { userId } = useAuth();
  const { kyc, setUserVerification } = useUserVerification();
  const { palette } = useTheme();
  const { setSnack } = useSnack();
  const { showIdentityDrawer, setShowIdentityDrawer } = useAllModal();
  const withdrawUrl = ROUTE_LIST.FEED_WITHDRAWAL.redirect + feedId;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const withdrawFunds = async () => {
    setLoading(true);
    if (endRaise) {
      await apiCall({
        url: APIS.FEED.UPDATE_BEG(feedId),
        data: { status: "ended" },
        method: "patch",
      });
    }
    if (checkKyc) {
      await verifyUserIdentity();
      setLoading(false);
      if (kyc === 0 || kyc === 2)
        return setShowIdentityDrawer(true, "deposit", withdrawUrl);
    }
    setLoading(false);
    navigate(withdrawUrl);
  };

  const { apiCall } = useApi();
  useEffect(() => {
    return () => {
      if (showIdentityDrawer) {
        setShowIdentityDrawer(false);
      }
    };
  }, [showIdentityDrawer, setShowIdentityDrawer]);

  const { apiCall: authApi } = useApi(true);
  const verifyUserIdentity = React.useCallback(
    async function () {
      let response = await authApi({
        url: "v2/accounts/check-verified-2",
        method: "post",
        data: { userId: userId },
      });
      let mobileVerification = response.data.data.mobile_verified.mobile;
      let emailVerification = response.data.data.email_verified.email;
      let kycVerification = response.data.data.isKyc.isKyc;
      setUserVerification(
        emailVerification,
        mobileVerification,
        kycVerification
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authApi, setUserVerification]
  );
  return (
    <>
      <ButtonWithLoading
        isLoading={loading}
        sx={{
          mt: { xs: "15px", md: "20px" },
          minWidth: "unset",
          width: "100%",
          paddingBlock: "15px",
          background: endRaise ? "primary.main" : palette.gradients["main"],
          textTransform: "unset",
          fontSize: { xs: "12px", md: "16px" },
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "22px",
          letterSpacing: "-0.408px",
          borderRadius: "10px",
          textWrap: "nowrap",
          "&.Mui-disabled": {
            color: endRaise ? "white.1000" : alpha(palette.white["1000"], 0.5),
          },
        }}
        fullWidth={true}
        variant="contained"
        onClick={() => {
          return setSnack(
            "Temporary this is not available. please contact administrator",
            "warning"
          );
          // if (endRaise) {
          //   setOpenDialog(true);
          // } else {
          //   withdrawFunds();
          // }
        }}
        disabled={disabled}
      >
        {children}
      </ButtonWithLoading>

      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <EndRaiseDialog
            isLoading={loading}
            handleClose={handleClose}
            deleteAccount={async () => {
              await withdrawFunds();
              setOpenDialog(false);
            }}
          />
        )}
      ></DialogBox>
    </>
  );
}
