import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApi from "../hooks/useApi";
import { Box } from "@mui/system";
import {
  Card,
  CardMedia,
  CircularProgress,
  Collapse,
  Divider,
  Stack,
  Typography,
  useTheme,
  alpha,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PaymentGateway from "../component/nuvei/PaymentGateway";
import {
  maxCharacterMessage,
  minCaharacterMessage,
} from "../utils/formErrorMessage";
import ROUTE_LIST from "../Routes/list";
import Layout from "../component/Layout";
import { useSnack } from "../hooks/store/useSnack";
import CardTextContent from "../component/donateAndTipping.jsx/CardTextContent";
import AmountInput from "../component/donateAndTipping.jsx/AmountInput";
import useThemeStore from "../hooks/store/useThemeStore";
import TipSelector from "../component/donateAndTipping.jsx/TipSelector";
import GuestUserFormDialog from "../component/donateAndTipping.jsx/GuestUserFormDialog";
import { useAuth } from "../hooks/store/useAuth";
import { useSettings } from "../hooks/store/useSettings";
import { useAllModal } from "../hooks/store/useAllModal";
import { LoadingButton } from "@mui/lab";
export default function DonateAndTipping() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { settings } = useSettings();
  const { setShowShareDrawer } = useAllModal();
  const [amount, setAmount] = useState(0);
  const [tipping, setTipping] = useState(0);
  const [tippingRange, setTippingRange] = useState(
    settings.defaultTippingRange
  );
  const { userId: webUserId } = useAuth();
  const [query] = useSearchParams();
  const userId = query.get("userId")
    ? query.get("userId")
    : !query.get("guest")
      ? webUserId
      : undefined;
  const [guestModal, setGuestModal] = useState(
    query.get("guest") || !userId ? true : false
  );
  const [donorDetails, setDonorDetails] = useState(false);
  const [guestFirstName, setGuestFirstName] = useState(false);
  const [guestLastName, setGuestLastName] = useState(false);
  const [guestEmail, setGuestEmail] = useState(false);
  const [allowMarketing, setAllowMarketing] = useState(true);

  const navigate = useNavigate();
  const [sessionToken, setSessionToken] = useState("");
  const [merchantDetails, setMerchantDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const begId = query.get("begId") || query.get("feed");
  const [begDetail, setBegDetail] = useState({
    title: "",
    thumbnail: "",
    description: "",
    author: {},
    createdAt: undefined,
    amountRaised: undefined,
    goalAmount: undefined,
    shareLink: undefined,
  });
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const { apiCall } = useApi();
  const { apiCall: getSessionTokenApiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const res = await apiCall({ url: "v3/begs/" + begId });
        const beg = res.data.data;
        if (beg.endedDate) {
          setSnack("Beg contribution period ended", "warning");
          return navigate("/");
        }
        if (res.data.success === true) {
          setBegDetail({
            title: beg.title,
            thumbnail: beg.videos[0]?.thumbLink,
            description: beg.textDescription,
            author: beg.author,
            createdAt: beg.createdAt,
            amountRaised: beg.amountRaised,
            goalAmount: beg.goalAmount,
            shareLink: beg.shareLink,
          });
        }
      } catch (error) {
        navigate(ROUTE_LIST.NOT_FOUND);
      }
      if (!userId) {
        setLoading(false);
        return;
      }
      try {
        const userRes = await apiCall({ url: "v3/users/" + userId });
        if (userRes.data.success === true) {
          setUserEmail(userRes.data.data.email);
          setUserName(
            userRes.data.data.firstName + " " + userRes.data.data.lastName
          );
          setDonorDetails(userRes.data.data);
        } else {
          throw Error("user not found");
        }
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setSnack(error.response.data.message, "error");
          return navigate("/404");
        }
        setGuestModal(true);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [begId, apiCall, navigate, userId]);

  // schema for logged in user
  const loggedInUserSchema = Yup.object({
    email: Yup.string().trim().email().optional(),
    amount: Yup.number()
      .typeError("Please enter valid amount")
      .min(
        settings.minimumDonationAmount || 5,
        `Minimum amount is $${settings.minimumDonationAmount || 5}`
      )
      .required("Amount is required"),
    tip: Yup.string()
      .optional()
      .test("maxTip", "Invalid contribution", function () {
        if (tipping < 0) {
          return false;
        }
        const maxTip = amount;
        return tipping > maxTip ? false : true;
      }),
    anonymous: Yup.boolean().default(false),
    name: Yup.string()
      .optional()
      .min(2, minCaharacterMessage("name", 2))
      .max(50, maxCharacterMessage("name", 50)),
  });

  const { register, handleSubmit, formState, watch } = useForm({
    resolver: yupResolver(loggedInUserSchema),
    mode: "onChange",
    defaultValues: { tip: 0 },
  });

  useEffect(() => {
    setTippingRange(settings.defaultTippingRange);
  }, [settings]);

  const donationAmount = watch("amount");
  const tipAmount = watch("tip");
  useEffect(() => {
    if (donationAmount) {
      let value = Number(donationAmount);
      value = Math.round(value);
      setAmount(value);
    }
  }, [donationAmount]);
  useEffect(() => {
    if (tipAmount) {
      setTipping(Math.round(tipAmount));
    }
  }, [tipAmount]);

  const submitHandler = async (values) => {
    try {
      setAmount(values.amount);
      setUserEmail(guestEmail ? guestEmail : userEmail);
      const result = await getSessionTokenApiCall({
        method: "post",
        url: "v3/webhooks/nuvei/",
        data: {
          begId: begId,
          amount: values?.amount,
          tip: tipping ? Number(tipping) : 0,
          email: guestEmail || undefined,
          donorFirstName: guestFirstName || undefined,
          donorLastName: guestLastName || undefined,
          userId: userId ? userId : undefined,
          transaction: "chipin",
          anonymous: values.anonymous ? true : undefined,
          allowMarketing: allowMarketing,
        },
      });
      setSessionToken(result.data.data.sessionToken);
      setMerchantDetails(result.data.data.merchantDetails);
    } catch (error) {
      if (error.response.status === 401) {
        setSnack(error.response.data.message, "error");
        return navigate("/");
      }
      if (error.response.status === 400) {
        setSnack(error.response.data.message, "error");
        return navigate("/");
      }
    }
  };
  if (!begId) {
    return <div>Beg not found</div>;
  }
  if (loading) {
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
          backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
        }}
      >
        <CircularProgress
          size={30}
          sx={{ ml: 0.8, color: darkMode ? "white.1000" : "primary.main" }}
        />
      </Box>
    );
  }

  function openShareDrawer() {
    setShowShareDrawer(
      true,
      begId,
      begDetail.title,
      begDetail.thumbnail,
      false,
      begDetail.description,
      begDetail.author,
      {
        amountRaised: begDetail.amountRaised,
        goalAmount: begDetail.goalAmount,
      },
      begDetail.shareLink,
      true,
      donorDetails
    );
  }
  return (
    <Layout>
      <Box
        sx={{
          position: "relative",
          zIndex: 1000,
          // overflowX: "hidden",
          marginInline: "15px",
          marginBlock: { xs: "15px", sm: "none" },
        }}
      >
        <Box
          width={"100%"}
          sx={{
            p: { xs: "10px", sm: "15px 25px" },
            backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
          }}
          mt={{ ssm: "30px", sm: "30px" }}
          mx={"auto"}
          maxWidth={{ md: "673px" }}
          borderRadius={{ xs: "15px", md: "8px !important" }}
        >
          <Collapse in={!Boolean(sessionToken)}>
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Donation
            </Typography>
            <Card
              sx={{
                mt: { xs: "10px", md: "15px" },
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "120px 1fr",
                  md: "255px 1fr",
                },
                gap: { xs: "15px", md: "25px" },
                backgroundColor: "transparent",
                boxShadow: "unset",
              }}
            >
              <CardMedia
                sx={{
                  height: { xs: 150, sm: 120, md: 150 },
                  width: "100%",
                  borderRadius: "10px",
                }}
                image={begDetail.thumbnail}
              />
              <CardTextContent
                title={begDetail.title}
                description={begDetail.description}
                author={begDetail.author}
              />
            </Card>
            <form onSubmit={handleSubmit(submitHandler)}>
              <AmountInput
                name={"amount"}
                inputLabel={"Enter your donation"}
                type="number"
                register={register}
                errors={formState.errors}
                boxSX={{ marginTop: { xs: "20px", sm: "35px" } }}
                onChange={(event) => {
                  setAmount(Number(event.target.value));
                }}
                setAmount={setAmount}
                amount={amount}
              />
              {begDetail.createdAt > settings.revisedFeesStructureDate && (
                <>
                  <Typography
                    sx={{
                      mt: { xs: "20px", sm: "35px" },
                      color: darkMode ? "white.1000" : "black.1000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    Contribute to PloomSocial
                  </Typography>
                  <Typography
                    sx={{
                      mt: "10px",
                      color: darkMode
                        ? alpha(palette.white[1000], 0.7)
                        : "black.1000",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    With a commitment to community-driven support, PloomSocial
                    sustains its service by relying on the valuable
                    contributions from donors like you, allowing organizers to
                    benefit from a 0% platform fee.
                  </Typography>
                </>
              )}
              {begDetail.createdAt > settings.revisedFeesStructureDate && (
                <TipSelector
                  name={"tip"}
                  register={register}
                  errors={formState.errors}
                  amount={amount}
                  tippingRange={tippingRange}
                  setTippingRange={setTippingRange}
                  setTipping={setTipping}
                  tipping={tipping}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (inputValue.trim() !== "") {
                      setTipping(Math.round(Number(inputValue)));
                    } else {
                      setTipping(0);
                    }
                  }}
                />
              )}
              <Typography
                sx={{
                  mt: "25px",
                  color: darkMode ? "white.1000" : "black.1000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", sm: "18px" },
                  fontStyle: "normal",
                  fontWeight: { xs: 600, sm: 700 },
                  lineHeight: "normal",
                }}
              >
                Your donation
              </Typography>
              <Stack
                sx={{
                  mt: { xs: "10px", sm: "15px" },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: darkMode ? "white.1000" : "black.1000",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Your donation
                </Typography>
                <Typography
                  sx={{
                    color: darkMode ? "white.1000" : "black.1000",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  ${parseFloat(Math.round(amount) || 0).toFixed(2)}
                </Typography>
              </Stack>
              {begDetail.createdAt > settings.revisedFeesStructureDate && (
                <>
                  <Stack
                    sx={{
                      mt: { xs: "10px", sm: "15px" },
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      PloomSocial Platform Support
                    </Typography>
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      ${parseFloat(Math.round(tipping) || 0).toFixed(2)}
                    </Typography>
                  </Stack>
                  <Divider
                    sx={{
                      mt: "15px",
                      "&.MuiDivider-root": {
                        borderColor: darkMode
                          ? "neutral.650"
                          : alpha(palette.primary.disabledLight, 0.5),
                      },
                    }}
                  />
                  <Stack
                    sx={{
                      mt: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      Total Amount
                    </Typography>
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      $
                      {begDetail.createdAt > settings.revisedFeesStructureDate
                        ? parseFloat(
                            Number(Math.round(amount) || 0) +
                              Number(Math.round(tipping) || 0)
                          ).toFixed(2)
                        : parseFloat(Math.round(amount) || 0).toFixed(2)}
                    </Typography>
                  </Stack>
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: darkMode
                        ? alpha(palette.white[1000], 0.7)
                        : "black.1000",
                      "&.Mui-checked,": {
                        color: darkMode ? "white.1000" : "primary.main",
                      },
                      "&.MuiCheckbox-root": { p: 0, mr: "10px" },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      display: "inline",
                      color: darkMode
                        ? alpha(palette.white[1000], 0.7)
                        : "black.1000",
                    }}
                  >
                    Donate as a anonymous
                  </Typography>
                }
                name="anonymous"
                {...register("anonymous")}
                sx={{
                  mt: { xs: 1, sm: 2 },
                  mx: 0,
                  display: "block",
                  width: "100%",
                }}
              />
              <LoadingButton
                color="primary"
                disabled={amount < 5 || (amount >= 5 && tipping > amount)}
                loading={formState.isSubmitting || isLoading}
                size="12px"
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  background: palette.gradients.main,
                  mt: "25px",
                  padding: "12px 16px",
                  borderRadius: "32px",
                  textTransform: "capitalize",
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: "white.1000",
                  },
                  "&.Mui-disabled": {
                    background: darkMode
                      ? palette.grey[550]
                      : palette.primary.disabledLight,
                    color: isLoading
                      ? "transparent"
                      : darkMode
                        ? "#BEBEBE"
                        : "white.1000",
                  },
                }}
              >
                Donate Now
              </LoadingButton>
            </form>
          </Collapse>
          <Collapse in={Boolean(sessionToken)}>
            {sessionToken !== "" && (
              <>
                <PaymentGateway
                  amount={
                    begDetail.createdAt > settings.revisedFeesStructureDate
                      ? Math.round(Number(amount) + Number(tipping))
                      : Math.round(amount)
                  }
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                  setAmount={setAmount}
                  setActiveButton={() => {}}
                  begId={begId}
                  email={userEmail}
                  offAutoClose
                  name={
                    userName ? userName : `${guestFirstName} ${guestLastName}`
                  }
                  redirectToImmersive={true}
                  merchantDetails={merchantDetails}
                  openShareDrawer={openShareDrawer}
                />
              </>
            )}
          </Collapse>
        </Box>

        {/* modal for get name and email from guest user */}

        <GuestUserFormDialog
          open={guestModal}
          setOpen={setGuestModal}
          setGuestEmail={setGuestEmail}
          setGuestFirstName={setGuestFirstName}
          setGuestLastName={setGuestLastName}
          allowMarketing={allowMarketing}
          setAllowMarketing={setAllowMarketing}
          setDonorDetails={setDonorDetails}
        />
      </Box>
    </Layout>
  );
}
